<template>
  <div class="bg-gray-100 flex-grow">
    <div class="w-full max-w-screen-xl relative mx-auto py-8 px-4">
      <div class="bg-white rounded-lg shadow p-6 max-w-screen-sm text-center mx-auto h-64 flex flex-col justify-center">
        <p
          v-if="!$route.query.error"
          class="mb-6 font-medium text-xl"
        >
          Importing profile from LinkedIn...
        </p>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import { getLocalStorage } from '@utils/localStorage'

export default {
  components: {
    Loader
  },

  data() {
    return {
      profile: null
    }
  },

  computed: {
    /**
     * @return {String}
     */
    authCode() {
      return this.$route.query.code
    },

    /**
     * @return {String}
     */
    stateCode() {
      return this.$route.query.state
    },

    /**
     * LinkedIn says: "Before you use the authorization code, your application should ensure that the value returned in the state parameter matches the state value from your original authorization code request."
     * https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?tabs=HTTPS#member-approves-request
     * @return {Boolean}
     */
    stateCodesMatch() {
      if (!getLocalStorage('candidateWizard.linkedInState')) {
        return
      }
      return this.stateCode === getLocalStorage('candidateWizard.linkedInState')
    }
  },

  mounted() {
    if (this.$route.query.error) {
      this.$router.push({ name: 'candidate-wizard-profile-edit' })
    } else {
      this.getLinkedInProfile()
    }
  },

  methods: {
    getLinkedInProfile() {
      // Request profile and store in candidateWizard store
      // so we can move back to the profile URL with the data
      console.log('Getting LinkedIn profile from Picked API')
    }
  }
}
</script>
